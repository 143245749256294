import { CONSENT_GROUP_TYPES, isCookieAllowed, parseCookieConsentCookie, parseCookieConsentGroups } from './utils';
export default (function stylesCookiePlugin(context, inject) {
    const constentHandler = new CookieConsentHandler(context);
    constentHandler.initialize();
    const api = {
        isCookieAllowed(type) {
            return isCookieAllowed(type, constentHandler.allowedCookies);
        },
        onConsentChanged(listener) {
            if (process.client) {
                constentHandler.addChangeListener(listener);
            }
            listener();
        },
    };
    inject('cookieConsent', api);
});
var UpdateConsentStatus;
(function (UpdateConsentStatus) {
    UpdateConsentStatus[UpdateConsentStatus["NOT_UPDATED"] = 0] = "NOT_UPDATED";
    UpdateConsentStatus[UpdateConsentStatus["UPDATING"] = 1] = "UPDATING";
    UpdateConsentStatus[UpdateConsentStatus["UPDATED"] = 2] = "UPDATED";
})(UpdateConsentStatus || (UpdateConsentStatus = {}));
class CookieConsentHandler {
    constructor(context) {
        this.context = context;
        this.clientSideListeners = [];
        this.listenerTimeout = null;
        this.updatedFromQueryStatus = UpdateConsentStatus.NOT_UPDATED;
        const cookieValue = context.$cookies.get('OptanonConsent');
        this.allowedCookies = parseCookieConsentCookie(cookieValue);
    }
    initialize() {
        // On the client-side allowed groups to be updated through a global handler callback.
        if (process.client) {
            window.consentPluginHandler = async (groups) => {
                const queryGroups = this.getConsentGroupsFromQuery();
                if (queryGroups && window.OneTrust && this.updatedFromQueryStatus !== UpdateConsentStatus.UPDATED) {
                    if (this.updatedFromQueryStatus === UpdateConsentStatus.UPDATING) {
                        // Will update allowed cookies when finished.
                        return;
                    }
                    this.updatedFromQueryStatus = UpdateConsentStatus.UPDATING;
                    for (const group of queryGroups) {
                        // Can throw for groups that we don't currently have any cookies for.
                        await window.OneTrust.UpdateConsent('Category', group).catch(() => { });
                    }
                    this.updatedFromQueryStatus = UpdateConsentStatus.UPDATED;
                    if (window.OptanonActiveGroups) {
                        this.updateAllowedCookies(window.OptanonActiveGroups.split(','));
                    }
                }
                else {
                    this.updateAllowedCookies(groups.split(','));
                }
            };
            if (window.OptanonActiveGroups) {
                this.updateAllowedCookies(window.OptanonActiveGroups.split(','));
            }
        }
    }
    getConsentGroupsFromQuery() {
        const queryGroups = this.context.route.query.consentStatus;
        if (typeof queryGroups === 'string') {
            return queryGroups.split(',');
        }
        return null;
    }
    updateAllowedCookies(groups) {
        const newConsent = parseCookieConsentGroups(groups);
        let consentChanged = false;
        let consentRemoved = false;
        for (const group of CONSENT_GROUP_TYPES) {
            if (this.allowedCookies[group] !== newConsent[group]) {
                consentChanged = true;
                if (!newConsent[group]) {
                    consentRemoved = true;
                }
                break;
            }
        }
        if (!consentChanged) {
            return;
        }
        this.allowedCookies = newConsent;
        if (this.listenerTimeout) {
            clearTimeout(this.listenerTimeout);
            this.listenerTimeout = null;
        }
        this.listenerTimeout = setTimeout(() => {
            this.clientSideListeners.forEach(listener => listener());
            if (consentRemoved) {
                if (!window.location.origin.includes('stage')) {
                    window.location.reload();
                }
                return;
            }
        }, 500);
    }
    addChangeListener(listener) {
        this.clientSideListeners.push(listener);
    }
}


export default {
    props: {
        /** @type {import('vue').PropOptions<TIDAL.MenuItem[]>} */
        items: {
            type: Array,
            required: true,
        },
        active: {
            type: String,
            default: null,
        },
        increaseContrast: {
            type: Boolean,
            default: null,
        },
    },
};


export default {
    props: {
        sticky: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        logout() {
            this.$tidalAuth.logout({
                stayOnPage: true,
                redirectHandler(redirectUrl) {
                    location.assign(redirectUrl);
                },
            });
        },
        /**
         * @param {string} translationKey
         * @return {string | null} The translation.
         */
        getTranslation(translationKey) {
            const translation = String(this.$t(`webCommon.header.${translationKey}`));

            // Special case string that only have translation in some languages.
            // Make sure we don't return the translation key (default for
            // vue-i18n if string doesn't exist) and instead return null;
            if (translationKey === 'startFreeTrialShortText'
                && translation.endsWith('.startFreeTrialShortText')) {
                return null;
            }

            return translation;
        },
    },
};

import locale9ec66352 from '../../app/lang/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","preserveDirectiveContent":true},
  vueI18nLoader: false,
  locales: [{"code":"ar","iso":"es-AR","file":"es.json","lang":"es","name":"Argentina"},{"code":"bg","iso":"bg-BG","file":"bg.json","lang":"bg","name":"Bulgaria"},{"code":"cl","iso":"es-CL","file":"es.json","lang":"es","name":"Chile"},{"code":"co","iso":"es-CO","file":"es.json","lang":"es","name":"Colombia"},{"code":"de","iso":"de-DE","file":"de.json","lang":"de","name":"Germany"},{"code":"do","iso":"es-DO","file":"es.json","lang":"es","name":"Dominican Republic"},{"code":"en","iso":"en-US","file":"en.json","lang":"en","name":"English"},{"code":"es","iso":"es-ES","file":"es.json","lang":"es","name":"Spain"},{"code":"fr","iso":"fr-FR","file":"fr.json","lang":"fr","name":"France"},{"code":"hr","iso":"hr-HR","file":"hr.json","lang":"hr","name":"Croatia"},{"code":"it","iso":"it-IT","file":"it.json","lang":"it","name":"Italy"},{"code":"ja","iso":"ja-JP","file":"ja.json","lang":"ja","name":"Japan"},{"code":"mx","iso":"es-MX","file":"es.json","lang":"es","name":"Mexico"},{"code":"no","iso":"no-NO","file":"no.json","lang":"no","name":"Norway"},{"code":"pe","iso":"es-PE","file":"es.json","lang":"es","name":"Peru"},{"code":"pl","iso":"pl-PL","file":"pl.json","lang":"pl","name":"Poland"},{"code":"pr","iso":"es-PR","file":"es.json","lang":"es","name":"Puerto Rico"},{"code":"pt","iso":"pt-PT","file":"pt.json","lang":"pt","name":"Portugal"},{"code":"pt-BR","iso":"pt-BR","file":"pt_BR.json","lang":"pt-BR","name":"Brazil"},{"code":"sl","iso":"sl-SI","file":"sl.json","lang":"sl","name":"Slovenia"},{"code":"sr","iso":"sr-RS","file":"sr.json","lang":"sr","name":"Serbia"},{"code":"us","iso":"en-US","file":"en.json","lang":"en","name":"United States"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":30,"cookieCrossOrigin":false,"cookieDomain":"tidal.com","cookieKey":"app_lang","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://tidal.com/magazine",
  vuex: false,
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ar","iso":"es-AR","file":"es.json","lang":"es","name":"Argentina"},{"code":"bg","iso":"bg-BG","file":"bg.json","lang":"bg","name":"Bulgaria"},{"code":"cl","iso":"es-CL","file":"es.json","lang":"es","name":"Chile"},{"code":"co","iso":"es-CO","file":"es.json","lang":"es","name":"Colombia"},{"code":"de","iso":"de-DE","file":"de.json","lang":"de","name":"Germany"},{"code":"do","iso":"es-DO","file":"es.json","lang":"es","name":"Dominican Republic"},{"code":"en","iso":"en-US","file":"en.json","lang":"en","name":"English"},{"code":"es","iso":"es-ES","file":"es.json","lang":"es","name":"Spain"},{"code":"fr","iso":"fr-FR","file":"fr.json","lang":"fr","name":"France"},{"code":"hr","iso":"hr-HR","file":"hr.json","lang":"hr","name":"Croatia"},{"code":"it","iso":"it-IT","file":"it.json","lang":"it","name":"Italy"},{"code":"ja","iso":"ja-JP","file":"ja.json","lang":"ja","name":"Japan"},{"code":"mx","iso":"es-MX","file":"es.json","lang":"es","name":"Mexico"},{"code":"no","iso":"no-NO","file":"no.json","lang":"no","name":"Norway"},{"code":"pe","iso":"es-PE","file":"es.json","lang":"es","name":"Peru"},{"code":"pl","iso":"pl-PL","file":"pl.json","lang":"pl","name":"Poland"},{"code":"pr","iso":"es-PR","file":"es.json","lang":"es","name":"Puerto Rico"},{"code":"pt","iso":"pt-PT","file":"pt.json","lang":"pt","name":"Portugal"},{"code":"pt-BR","iso":"pt-BR","file":"pt_BR.json","lang":"pt-BR","name":"Brazil"},{"code":"sl","iso":"sl-SI","file":"sl.json","lang":"sl","name":"Slovenia"},{"code":"sr","iso":"sr-RS","file":"sr.json","lang":"sr","name":"Serbia"},{"code":"us","iso":"en-US","file":"en.json","lang":"en","name":"United States"}],
  localeCodes: ["ar","bg","cl","co","de","do","en","es","fr","hr","it","ja","mx","no","pe","pl","pr","pt","pt-BR","sl","sr","us"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "s",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "a",
  16: "p",
  17: "p",
  18: "/",
  19: "l",
  20: "a",
  21: "n",
  22: "g",
  23: "/",
  24: "e",
  25: "s",
  26: ".",
  27: "j",
  28: "s",
  29: "o",
  30: "n",
  31: "\"",
  32: ",",
  33: "\"",
  34: "b",
  35: "g",
  36: ".",
  37: "j",
  38: "s",
  39: "o",
  40: "n",
  41: "\"",
  42: ":",
  43: "\"",
  44: ".",
  45: ".",
  46: "/",
  47: "a",
  48: "p",
  49: "p",
  50: "/",
  51: "l",
  52: "a",
  53: "n",
  54: "g",
  55: "/",
  56: "b",
  57: "g",
  58: ".",
  59: "j",
  60: "s",
  61: "o",
  62: "n",
  63: "\"",
  64: ",",
  65: "\"",
  66: "d",
  67: "e",
  68: ".",
  69: "j",
  70: "s",
  71: "o",
  72: "n",
  73: "\"",
  74: ":",
  75: "\"",
  76: ".",
  77: ".",
  78: "/",
  79: "a",
  80: "p",
  81: "p",
  82: "/",
  83: "l",
  84: "a",
  85: "n",
  86: "g",
  87: "/",
  88: "d",
  89: "e",
  90: ".",
  91: "j",
  92: "s",
  93: "o",
  94: "n",
  95: "\"",
  96: ",",
  97: "\"",
  98: "e",
  99: "n",
  100: ".",
  101: "j",
  102: "s",
  103: "o",
  104: "n",
  105: "\"",
  106: ":",
  107: "\"",
  108: ".",
  109: ".",
  110: "/",
  111: "a",
  112: "p",
  113: "p",
  114: "/",
  115: "l",
  116: "a",
  117: "n",
  118: "g",
  119: "/",
  120: "e",
  121: "n",
  122: ".",
  123: "j",
  124: "s",
  125: "o",
  126: "n",
  127: "\"",
  128: ",",
  129: "\"",
  130: "f",
  131: "r",
  132: ".",
  133: "j",
  134: "s",
  135: "o",
  136: "n",
  137: "\"",
  138: ":",
  139: "\"",
  140: ".",
  141: ".",
  142: "/",
  143: "a",
  144: "p",
  145: "p",
  146: "/",
  147: "l",
  148: "a",
  149: "n",
  150: "g",
  151: "/",
  152: "f",
  153: "r",
  154: ".",
  155: "j",
  156: "s",
  157: "o",
  158: "n",
  159: "\"",
  160: ",",
  161: "\"",
  162: "h",
  163: "r",
  164: ".",
  165: "j",
  166: "s",
  167: "o",
  168: "n",
  169: "\"",
  170: ":",
  171: "\"",
  172: ".",
  173: ".",
  174: "/",
  175: "a",
  176: "p",
  177: "p",
  178: "/",
  179: "l",
  180: "a",
  181: "n",
  182: "g",
  183: "/",
  184: "h",
  185: "r",
  186: ".",
  187: "j",
  188: "s",
  189: "o",
  190: "n",
  191: "\"",
  192: ",",
  193: "\"",
  194: "i",
  195: "t",
  196: ".",
  197: "j",
  198: "s",
  199: "o",
  200: "n",
  201: "\"",
  202: ":",
  203: "\"",
  204: ".",
  205: ".",
  206: "/",
  207: "a",
  208: "p",
  209: "p",
  210: "/",
  211: "l",
  212: "a",
  213: "n",
  214: "g",
  215: "/",
  216: "i",
  217: "t",
  218: ".",
  219: "j",
  220: "s",
  221: "o",
  222: "n",
  223: "\"",
  224: ",",
  225: "\"",
  226: "j",
  227: "a",
  228: ".",
  229: "j",
  230: "s",
  231: "o",
  232: "n",
  233: "\"",
  234: ":",
  235: "\"",
  236: ".",
  237: ".",
  238: "/",
  239: "a",
  240: "p",
  241: "p",
  242: "/",
  243: "l",
  244: "a",
  245: "n",
  246: "g",
  247: "/",
  248: "j",
  249: "a",
  250: ".",
  251: "j",
  252: "s",
  253: "o",
  254: "n",
  255: "\"",
  256: ",",
  257: "\"",
  258: "n",
  259: "o",
  260: ".",
  261: "j",
  262: "s",
  263: "o",
  264: "n",
  265: "\"",
  266: ":",
  267: "\"",
  268: ".",
  269: ".",
  270: "/",
  271: "a",
  272: "p",
  273: "p",
  274: "/",
  275: "l",
  276: "a",
  277: "n",
  278: "g",
  279: "/",
  280: "n",
  281: "o",
  282: ".",
  283: "j",
  284: "s",
  285: "o",
  286: "n",
  287: "\"",
  288: ",",
  289: "\"",
  290: "p",
  291: "l",
  292: ".",
  293: "j",
  294: "s",
  295: "o",
  296: "n",
  297: "\"",
  298: ":",
  299: "\"",
  300: ".",
  301: ".",
  302: "/",
  303: "a",
  304: "p",
  305: "p",
  306: "/",
  307: "l",
  308: "a",
  309: "n",
  310: "g",
  311: "/",
  312: "p",
  313: "l",
  314: ".",
  315: "j",
  316: "s",
  317: "o",
  318: "n",
  319: "\"",
  320: ",",
  321: "\"",
  322: "p",
  323: "t",
  324: ".",
  325: "j",
  326: "s",
  327: "o",
  328: "n",
  329: "\"",
  330: ":",
  331: "\"",
  332: ".",
  333: ".",
  334: "/",
  335: "a",
  336: "p",
  337: "p",
  338: "/",
  339: "l",
  340: "a",
  341: "n",
  342: "g",
  343: "/",
  344: "p",
  345: "t",
  346: ".",
  347: "j",
  348: "s",
  349: "o",
  350: "n",
  351: "\"",
  352: ",",
  353: "\"",
  354: "p",
  355: "t",
  356: "_",
  357: "B",
  358: "R",
  359: ".",
  360: "j",
  361: "s",
  362: "o",
  363: "n",
  364: "\"",
  365: ":",
  366: "\"",
  367: ".",
  368: ".",
  369: "/",
  370: "a",
  371: "p",
  372: "p",
  373: "/",
  374: "l",
  375: "a",
  376: "n",
  377: "g",
  378: "/",
  379: "p",
  380: "t",
  381: "_",
  382: "B",
  383: "R",
  384: ".",
  385: "j",
  386: "s",
  387: "o",
  388: "n",
  389: "\"",
  390: ",",
  391: "\"",
  392: "s",
  393: "l",
  394: ".",
  395: "j",
  396: "s",
  397: "o",
  398: "n",
  399: "\"",
  400: ":",
  401: "\"",
  402: ".",
  403: ".",
  404: "/",
  405: "a",
  406: "p",
  407: "p",
  408: "/",
  409: "l",
  410: "a",
  411: "n",
  412: "g",
  413: "/",
  414: "s",
  415: "l",
  416: ".",
  417: "j",
  418: "s",
  419: "o",
  420: "n",
  421: "\"",
  422: ",",
  423: "\"",
  424: "s",
  425: "r",
  426: ".",
  427: "j",
  428: "s",
  429: "o",
  430: "n",
  431: "\"",
  432: ":",
  433: "\"",
  434: ".",
  435: ".",
  436: "/",
  437: "a",
  438: "p",
  439: "p",
  440: "/",
  441: "l",
  442: "a",
  443: "n",
  444: "g",
  445: "/",
  446: "s",
  447: "r",
  448: ".",
  449: "j",
  450: "s",
  451: "o",
  452: "n",
  453: "\"",
  454: "}",
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'es.json': () => import('../../app/lang/es.json' /* webpackChunkName: "lang-es.json" */),
  'bg.json': () => import('../../app/lang/bg.json' /* webpackChunkName: "lang-bg.json" */),
  'de.json': () => import('../../app/lang/de.json' /* webpackChunkName: "lang-de.json" */),
  'fr.json': () => import('../../app/lang/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'hr.json': () => import('../../app/lang/hr.json' /* webpackChunkName: "lang-hr.json" */),
  'it.json': () => import('../../app/lang/it.json' /* webpackChunkName: "lang-it.json" */),
  'ja.json': () => import('../../app/lang/ja.json' /* webpackChunkName: "lang-ja.json" */),
  'no.json': () => import('../../app/lang/no.json' /* webpackChunkName: "lang-no.json" */),
  'pl.json': () => import('../../app/lang/pl.json' /* webpackChunkName: "lang-pl.json" */),
  'pt.json': () => import('../../app/lang/pt.json' /* webpackChunkName: "lang-pt.json" */),
  'pt_BR.json': () => import('../../app/lang/pt_BR.json' /* webpackChunkName: "lang-pt_BR.json" */),
  'sl.json': () => import('../../app/lang/sl.json' /* webpackChunkName: "lang-sl.json" */),
  'sr.json': () => import('../../app/lang/sr.json' /* webpackChunkName: "lang-sr.json" */),
}

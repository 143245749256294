/** @type {import('@nuxt/types').Plugin} */
export default (_, inject) => {
    /**
     * Global PID unique for every initialized embed used for communication.
     *
     * @type {Number}
     */
    let lastPid = 1;

    /**
     * Mapping from embed ID to iframe created for it. Used for looking up
     * iframe elements on getting messages from the embed.
     *
     * @type {Map<Number, HTMLFrameElement>}
     */
    const embedsMap = new Map();

    /**
     * Receives events for controlling the embed.
     *
     * @param {MessageEvent} event
     */
    const receiver = event => {
        let message = event.data;

        if (typeof message === 'string') {
            try {
                message = JSON.parse(message);
            } catch (e) {
                return;
            }
        }

        if (!message) {
            return;
        }

        if (!isNaN(message.height) && !isNaN(message.pid)) {
            const playerIframe = embedsMap.get(message.pid);
            if (playerIframe) {
                playerIframe.dispatchEvent(
                    new CustomEvent('heightchanged', { detail: message.height }));
            }
        } else if (!isNaN(message.activatePlayerId)) {
            for (const [playerId, playerIframe] of embedsMap.entries()) {
                if (!embedsMap.get(playerId)) {
                    continue;
                }

                if (playerId === Number.parseInt(message.activatePlayerId)) {
                    continue;
                }

                if (playerIframe.contentWindow) {
                    playerIframe.contentWindow.postMessage(
                        JSON.stringify({ commandName: 'pause' }), '*');
                }
            }
        }
    };

    window.addEventListener('message', receiver);

    inject('embedService', /** @type {import('../types').EmbedService} */({
        getNextPid() {
            return lastPid++;
        },
        registerEmbedFrame(playerId, playerIframe) {
            console.assert(
                !(playerId in embedsMap), 'Player ID already registered!');

            embedsMap.set(playerId, playerIframe);
        },
        unregisterEmbedFrame(playerId) {
            embedsMap.delete(playerId);
        },
    }));
};

import Vue from 'vue';

/** @type {import('@nuxt/types').Plugin} */
export default ({ app: { $currency, i18n } }, inject) => {
    const vm = new Vue({
        data() {
            /** @type {TIDAL.LocalePluginProperties} */
            const data = {
                availableLangs: [],
            };

            return data;
        },
        created() {
            const { locales } = i18n;
            const langs = [];

            for (const locale of locales) {
                const lang = typeof (locale) === 'string' ? locale : locale.lang;
                langs.push(lang);
            }

            this.availableLangs = langs;
        },
        methods: {
            /** @type {TIDAL.LocalePlugin['formatCurrency']} */
            formatCurrency(priceData) {
                const formatOptions = { locale: i18n.localeProperties.iso };
                return $currency.formatLocaleCurrency(priceData.price, priceData.currency, formatOptions);
            },
            /** @param {number} timestamp */
            formatDate(timestamp) {
                if (!timestamp) {
                    return '';
                }

                const date = new Date(timestamp);
                return date.toLocaleDateString(i18n.localeProperties.iso);
            },
        },
    });

    inject('locale', vm);
};

import Auth from './auth.js';

/** type {import('@nuxt/types').Plugin} */
export default async function(context, inject) {
    const auth = new Auth(context, {"defaultCookieOptions":{"secure":true,"path":"\u002F"},"endpoints":{"accessToken":"https:\u002F\u002Flogin.tidal.com\u002Foauth2\u002Ftoken","authorization":"https:\u002F\u002Flogin.tidal.com\u002Fauthorize","login":"\u002Flogin","logout":"\u002Flogout","oauthServerOrigin":"http:\u002F\u002Fauth.int.tidalhi.fi:8080","signup":"http:\u002F\u002Fuserwebapi.int.tidalhi.fi"},"excludeRoutes":[],"extraAuthParams":function ({ i18n }) {
      return { lang: i18n.localeProperties.lang };
    },"oauthParams":{"client_id":"geKr245pT9GH3AaS","redirect_uri":"https:\u002F\u002Ftidal.com\u002Fmagazine\u002Flogin\u002Ftidal\u002Freturn","response_type":"code"},"redirectBase":"https:\u002F\u002Ftidal.com\u002Fmagazine","storageKeys":{"authState":"auth.state","transientData":"auth.transient_data","returnUrl":"auth.return_url"},"vuex":{"namespace":"auth"}});
    await auth.init();

    inject('tidalAuth', auth);
}

import Vue from 'vue';

/**
 * @typedef {import('@tidal/styles-scroll').LoadHandler} LoadHandler
 */

/**
 * Map of callbacks for use with custom observers.
 *
 * @type {Map<HTMLElement, import('@tidal/styles-scroll').CustomCallback | import('@tidal/styles-scroll').CustomCallbackSet>}
 */
const callbackMap = new Map();

/**
 * Map of callbacks for use with custom observers.
 *
 * @type {Map<string, Array<HTMLElement>>}
 */
const triggerMap = new Map();

/**
 * Mapping of observe types to observer interfaces.
 *
 * @type {Map<String | HTMLElement, IntersectionObserver | null>}
 */
const observers = new Map();

/** @type {IntersectionObserverInit} */
const defaultConfig = {
    rootMargin: '200px',
    threshold: 0.01,
};
/** @type {IntersectionObserverInit} */
const defaultItemConfig = {
    rootMargin: '-10%',
    threshold: 0.01,
};

/** @type {LoadHandler} */
function loadImage(element, { intersecting }) {
    if (!intersecting) {
        return false;
    }
    const image = /** @type {HTMLImageElement} */(element);
    const src = image.getAttribute('data-src');
    if (!src) {
        return true;
    }

    if (!image.hasAttribute('data-loaded')) {
        image.onload = function() {
            image.className += ' fade-in tidal-lazy-image--handled';
            if (image.parentElement) {
                image.parentElement.className += ' tidal-lazy-image-wrapper--loaded';
            }
            image.onload = null;
        };
    }

    image.src = src;
    image.removeAttribute('data-src');

    const srcset = image.getAttribute('data-srcset');
    if (srcset) {
        image.srcset = srcset;
        image.removeAttribute('data-srcset');
    }

    image.setAttribute('data-loaded', 'true');

    return true;
}

/** @type {LoadHandler} */
function loadItem(element, { isAbove, intersecting }) {
    if (!intersecting) {
        return false;
    }
    const item = /** @type {HTMLElement} */(element);
    item.classList.add('tidal-scroll-item--handled');

    let animation = item.getAttribute('data-animation') || 'slide-in';
    if (animation === 'slide-in' && isAbove && !item.getAttribute('data-force-direction')) {
        animation = 'slide-in-above';
    }

    let delay = Number(item.getAttribute('data-delay'));
    const mobileDelay = Number(item.getAttribute('data-mobile') || undefined);
    const tabletDelay = Number(item.getAttribute('data-tablet') || undefined);
    if (!isNaN(mobileDelay) && window.matchMedia('(max-width: calc(48rem - 1px))').matches) {
        delay = mobileDelay;
    } else if (!isNaN(tabletDelay) && window.matchMedia('(min-width: 48rem) and (max-width: calc(62rem - 1px))').matches) {
        delay = tabletDelay;
    }

    const stagger = item.getAttribute('data-stagger');
    if (stagger && stagger !== 'null') {
        const staggerData = stagger.split(',');
        const rowIndex = Number(staggerData[0]) - 1;
        const rowTotal = Number(staggerData[1]);
        const rowPosition = ((rowIndex % rowTotal) || 0) + 1;
        delay = (delay || 0) + (rowPosition * 150);
    }

    if (delay) {
        item.style.animationDelay = (delay / 1000) + 's';
    }

    item.classList.add(animation);
    return true;
}

/** @type {LoadHandler} */
function invokeInitCallback(item, { isAbove, removeCallback, intersecting }) {
    if (intersecting) {
        const init = item.getAttribute('data-init');
        if (init) {
            const callbacks = triggerMap.get(init);
            if (callbacks) {
                callbacks.forEach(item => loadItem(item, { isAbove, removeCallback, intersecting }));
                triggerMap.delete(init);
            } else {
                console.warn('styles-scroll: Callback for element not found!');
            }
        }

        return true;
    }

    return false;
}

/** @type {LoadHandler} */
function invokeCustomCallback(element, { isAbove, removeCallback, intersecting }, entry) {
    const item = /** @type {HTMLElement} */(element);
    const callback = callbackMap.get(item);
    console.assert(callback !== undefined, 'styles-scroll: Callback for element not found!');

    if (callback) {
        if (typeof callback === 'function') {
            if (intersecting) {
                if (removeCallback) {
                    callbackMap.delete(item);
                }
                callback(item, isAbove, entry);
                return true;
            }
        } else if (typeof callback === 'object') {
            let called = false;

            if (intersecting && callback.in) {
                callback.in(item, isAbove, entry);
                called = true;
            } else if (callback.out) {
                callback.out(item, isAbove, entry);
                called = true;
            }

            if (called) {
                if (removeCallback) {
                    callbackMap.delete(item);
                }
                return true;
            }
        }
    }
    return false;
}

/**
 * @param {HTMLElement} element
 * @param {String | HTMLElement} observerName
 * @param {LoadHandler} callback
 * @param {IntersectionObserverInit} [config=defaultConfig]
 * @param {boolean} [repeat=false]
 */
function startObserving(element, observerName, callback, config, repeat) {
    if (observers.get(observerName) === undefined) {
        if ('IntersectionObserver' in window) {
            observers.set(observerName, new IntersectionObserver((entries, observer) => {
                for (const entry of entries) {
                    const bounds = entry.boundingClientRect;
                    const isAbove = bounds.top < 0 && bounds.top * -2 > bounds.height;

                    if (callback(entry.target, { isAbove, removeCallback: !repeat, intersecting: entry.isIntersecting }, entry)) {
                        if (!repeat) {
                            observer.unobserve(entry.target);
                        }
                    }
                }
            }, config || defaultConfig));
        } else {
            observers.set(observerName, null);
        }
    }

    const observer = observers.get(observerName);

    if (observer) {
        observer.observe(element);
    } else {
        // Fallback for browsers without intersection observer
        callback(element, { isAbove: false, removeCallback: true, intersecting: true });
    }
}

Vue.directive('scroll', {
    inserted(el, binding) {
        /** @type {import('@tidal/styles-scroll').VScrollOptions} */
        const bindingValue = binding.value || {};

        // Set values
        if (bindingValue.disabled || binding.value === null) {
            return;
        }

        // Set data attributes
        const ignore = ['type', 'function', 'config', 'repeat', 'trigger'];
        for (const [key, value] of Object.entries(bindingValue)) {
            if ((value || !isNaN(value)) && !ignore.includes(key)) {
                el.dataset[key] = value;
            }
        }

        // Find out observation type
        if ('function' in bindingValue) {
            console.assert(bindingValue.function, 'styles-scroll: The "function" must be truthy.');
            // Observe custom elements
            callbackMap.set(el, bindingValue.function);
            startObserving(
                el,
                bindingValue.type || el,
                invokeCustomCallback,
                bindingValue.config || defaultItemConfig,
                bindingValue.repeat,
            );
        } else if (bindingValue.init) {
            // Observe init scroll items
            console.assert(!(bindingValue.config && !bindingValue.type), 'styles-scroll: You should specify a type when using custom config!');
            startObserving(el, bindingValue.type || 'init', invokeInitCallback, bindingValue.config || defaultItemConfig);
        } else if (bindingValue.type === 'image') {
            // Observe images
            startObserving(el, 'image', loadImage);
        } else {
            // Observe scroll items
            el.classList.add('tidal-scroll-item');
            if (el.getAttribute('data-mobile') !== null) {
                el.classList.add('tidal-mobile-animation');
            }
            if (el.getAttribute('data-tablet') !== null) {
                el.classList.add('tidal-tablet-animation');
            }

            if (bindingValue.trigger) {
                const callback = triggerMap.get(bindingValue.trigger);
                return callback ? callback.push(el) : triggerMap.set(bindingValue.trigger, [el]);
            }

            startObserving(el, 'default', loadItem, defaultItemConfig);
        }
    },
    componentUpdated(el, binding) {
        /** @type {import('@tidal/styles-scroll').VScrollOptions} */
        const bindingValue = binding.value || {};

        // Set values
        if (bindingValue.disabled) {
            return;
        }

        if (bindingValue.type === 'image') {
            if (el.hasAttribute('data-loaded')) {
                loadImage(el, { isAbove: false, removeCallback: true, intersecting: true });
            }
        }
    },
    unbind(el, binding) {
        const bindingValue = binding.value || {};

        if (bindingValue.disabled || binding.value === null) {
            return;
        }

        const observerType = bindingValue.type || 'default';

        for (const [type, observer] of observers.entries()) {
            if (observerType === type) {
                if (observer) {
                    // Might have been already unobserved but it doesn't hurt.
                    observer.unobserve(el);
                }
                break;
            }
        }

        if (!bindingValue.init && bindingValue.function) {
            callbackMap.delete(bindingValue.type || el);
        }

        // We're not disconnecting observer when it no longer observes any
        // elements. It should be fine as there would be at most a couple of them.
    },
});

export const CONSENT_GROUP_TYPES = ['essential', 'functional', 'performance', 'socialMedia', 'targeting'];
export function parseCookieConsentGroups(groups) {
    return {
        essential: true,
        performance: groups.includes('C0002'),
        functional: groups.includes('C0003'),
        targeting: groups.includes('C0004'),
        socialMedia: groups.includes('C0005'),
    };
}
export function parseCookieConsentCookie(consentCookie) {
    // The cookie value has a format of an URL query.
    const groups = new URLSearchParams(consentCookie).get('groups') || '';
    return parseCookieConsentGroups(groups
        .split(',')
        .map(mapping => {
        const [group, enabled] = mapping.split(':');
        return enabled === '1' ? group : '';
    })
        .filter(group => group));
}
export function isCookieAllowed(type, cookies) {
    const types = typeof type === 'string' ? [type] : type;
    return types.some(type => cookies[type]);
}

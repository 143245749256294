import Vue from 'vue';

import createPageTags from '~/utils/metaCreator.js';

/** @type {import('vue').PluginObject<any>} */
const plugin = {
    install: VueInstance => {
        VueInstance.mixin({
            methods: {
                /**
                 * Gets the default tags.
                 *
                 * @return {import('vue-meta').MetaInfo} The default tags.
                 */
                getDefaultTags() {
                    // https://sentry.io/organizations/tidal-music-as/issues/1822704092/
                    if (!this.$route) {
                        return {};
                    }

                    const head = this.$nuxtI18nHead({ addSeoAttributes: true });

                    if (!head.meta) {
                        head.meta = [];
                    }

                    const url = `${process.env.API_URL_BROWSER}${this.$route.path}`;
                    head.meta.push({ property: 'og:url', content: url, hid: 'og_url' });

                    return head;
                },
                createPageTags,
            },
        });
    },
};

export default () => Vue.use(plugin);

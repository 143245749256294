export const TidalLanguageSwitcher = () => import('../../node_modules/@tidal/language-switcher/src/components/tidal-language-switcher.vue' /* webpackChunkName: "components/tidal-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const TidalAccordion = () => import('../../node_modules/@tidal/styles-accordion/src/components/tidal-accordion.vue' /* webpackChunkName: "components/tidal-accordion" */).then(c => wrapFunctional(c.default || c))
export const TidalTransitionExpand = () => import('../../node_modules/@tidal/styles-animation/src/components/tidal-transition-expand.vue' /* webpackChunkName: "components/tidal-transition-expand" */).then(c => wrapFunctional(c.default || c))
export const TidalArticleCardFeatured = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-article-card-featured.vue' /* webpackChunkName: "components/tidal-article-card-featured" */).then(c => wrapFunctional(c.default || c))
export const TidalArticleCardTop = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-article-card-top.vue' /* webpackChunkName: "components/tidal-article-card-top" */).then(c => wrapFunctional(c.default || c))
export const TidalArticleCard = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-article-card.vue' /* webpackChunkName: "components/tidal-article-card" */).then(c => wrapFunctional(c.default || c))
export const TidalArticleCards = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-article-cards.vue' /* webpackChunkName: "components/tidal-article-cards" */).then(c => wrapFunctional(c.default || c))
export const TidalArticleList = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-article-list.vue' /* webpackChunkName: "components/tidal-article-list" */).then(c => wrapFunctional(c.default || c))
export const TidalCardWrapper = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-card-wrapper.vue' /* webpackChunkName: "components/tidal-card-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TidalContentCoverGrid = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-content-cover-grid.vue' /* webpackChunkName: "components/tidal-content-cover-grid" */).then(c => wrapFunctional(c.default || c))
export const TidalContentCta = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-content-cta.vue' /* webpackChunkName: "components/tidal-content-cta" */).then(c => wrapFunctional(c.default || c))
export const TidalContentFiftyFifty = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-content-fifty-fifty.vue' /* webpackChunkName: "components/tidal-content-fifty-fifty" */).then(c => wrapFunctional(c.default || c))
export const TidalContentHeader = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-content-header.vue' /* webpackChunkName: "components/tidal-content-header" */).then(c => wrapFunctional(c.default || c))
export const TidalContentThree = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-content-three.vue' /* webpackChunkName: "components/tidal-content-three" */).then(c => wrapFunctional(c.default || c))
export const TidalContentWrapper = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-content-wrapper.vue' /* webpackChunkName: "components/tidal-content-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TidalPill = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-pill.vue' /* webpackChunkName: "components/tidal-pill" */).then(c => wrapFunctional(c.default || c))
export const TidalSectionLabel = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-section-label.vue' /* webpackChunkName: "components/tidal-section-label" */).then(c => wrapFunctional(c.default || c))
export const TidalSectionWrapper = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-section-wrapper.vue' /* webpackChunkName: "components/tidal-section-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TidalTags = () => import('../../node_modules/@tidal/styles-content/src/components/tidal-tags.vue' /* webpackChunkName: "components/tidal-tags" */).then(c => wrapFunctional(c.default || c))
export const TidalDropdown = () => import('../../node_modules/@tidal/styles-dropdown/src/components/tidal-dropdown.vue' /* webpackChunkName: "components/tidal-dropdown" */).then(c => wrapFunctional(c.default || c))
export const TidalEmbed = () => import('../../node_modules/@tidal/styles-embed/src/components/tidal-embed.vue' /* webpackChunkName: "components/tidal-embed" */).then(c => wrapFunctional(c.default || c))
export const TidalFooterLink = () => import('../../node_modules/@tidal/styles-footer/src/components/tidal-footer-link.vue' /* webpackChunkName: "components/tidal-footer-link" */).then(c => wrapFunctional(c.default || c))
export const TidalFooter = () => import('../../node_modules/@tidal/styles-footer/src/components/tidal-footer.vue' /* webpackChunkName: "components/tidal-footer" */).then(c => wrapFunctional(c.default || c))
export const TidalBirthday = () => import('../../node_modules/@tidal/styles-form/src/components/tidal-birthday.vue' /* webpackChunkName: "components/tidal-birthday" */).then(c => wrapFunctional(c.default || c))
export const TidalCheckbox = () => import('../../node_modules/@tidal/styles-form/src/components/tidal-checkbox.vue' /* webpackChunkName: "components/tidal-checkbox" */).then(c => wrapFunctional(c.default || c))
export const TidalInput = () => import('../../node_modules/@tidal/styles-form/src/components/tidal-input.vue' /* webpackChunkName: "components/tidal-input" */).then(c => wrapFunctional(c.default || c))
export const TidalSearch = () => import('../../node_modules/@tidal/styles-form/src/components/tidal-search.vue' /* webpackChunkName: "components/tidal-search" */).then(c => wrapFunctional(c.default || c))
export const TidalSelect = () => import('../../node_modules/@tidal/styles-form/src/components/tidal-select.vue' /* webpackChunkName: "components/tidal-select" */).then(c => wrapFunctional(c.default || c))
export const TidalSwitch = () => import('../../node_modules/@tidal/styles-form/src/components/tidal-switch.vue' /* webpackChunkName: "components/tidal-switch" */).then(c => wrapFunctional(c.default || c))
export const TidalTextarea = () => import('../../node_modules/@tidal/styles-form/src/components/tidal-textarea.vue' /* webpackChunkName: "components/tidal-textarea" */).then(c => wrapFunctional(c.default || c))
export const InternalTidalSearchIcon = () => import('../../node_modules/@tidal/styles-form/src/components/internal/tidal-search-icon.vue' /* webpackChunkName: "components/internal-tidal-search-icon" */).then(c => wrapFunctional(c.default || c))
export const TidalHeaderLink = () => import('../../node_modules/@tidal/styles-header/src/components/tidal-header-link.vue' /* webpackChunkName: "components/tidal-header-link" */).then(c => wrapFunctional(c.default || c))
export const TidalHeaderUser = () => import('../../node_modules/@tidal/styles-header/src/components/tidal-header-user.vue' /* webpackChunkName: "components/tidal-header-user" */).then(c => wrapFunctional(c.default || c))
export const TidalHeader = () => import('../../node_modules/@tidal/styles-header/src/components/tidal-header.vue' /* webpackChunkName: "components/tidal-header" */).then(c => wrapFunctional(c.default || c))
export const TidalIcon = () => import('../../node_modules/@tidal/styles-icons/src/components/tidal-icon.vue' /* webpackChunkName: "components/tidal-icon" */).then(c => wrapFunctional(c.default || c))
export const TidalImage = () => import('../../node_modules/@tidal/styles-image/src/components/tidal-image.vue' /* webpackChunkName: "components/tidal-image" */).then(c => wrapFunctional(c.default || c))
export const TidalLink = () => import('../../node_modules/@tidal/styles-link/src/components/tidal-link.vue' /* webpackChunkName: "components/tidal-link" */).then(c => wrapFunctional(c.default || c))
export const TidalPagination = () => import('../../node_modules/@tidal/styles-navigation/src/components/tidal-pagination.vue' /* webpackChunkName: "components/tidal-pagination" */).then(c => wrapFunctional(c.default || c))
export const TidalSecondaryNavigation = () => import('../../node_modules/@tidal/styles-navigation/src/components/tidal-secondary-navigation.vue' /* webpackChunkName: "components/tidal-secondary-navigation" */).then(c => wrapFunctional(c.default || c))
export const DefaultLayout = () => import('../../app/components/default-layout.vue' /* webpackChunkName: "components/default-layout" */).then(c => wrapFunctional(c.default || c))
export const FooterComponent = () => import('../../app/components/footer-component.vue' /* webpackChunkName: "components/footer-component" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponent = () => import('../../app/components/header-component.vue' /* webpackChunkName: "components/header-component" */).then(c => wrapFunctional(c.default || c))
export const MagazineContent = () => import('../../app/components/magazine-content.vue' /* webpackChunkName: "components/magazine-content" */).then(c => wrapFunctional(c.default || c))
export const MagazineHeader = () => import('../../app/components/magazine-header.vue' /* webpackChunkName: "components/magazine-header" */).then(c => wrapFunctional(c.default || c))
export const MagazineImage = () => import('../../app/components/magazine-image.vue' /* webpackChunkName: "components/magazine-image" */).then(c => wrapFunctional(c.default || c))
export const MagazineListFiftyFifty = () => import('../../app/components/magazine-list-fifty-fifty.vue' /* webpackChunkName: "components/magazine-list-fifty-fifty" */).then(c => wrapFunctional(c.default || c))
export const MagazineListFull = () => import('../../app/components/magazine-list-full.vue' /* webpackChunkName: "components/magazine-list-full" */).then(c => wrapFunctional(c.default || c))
export const MagazinePage = () => import('../../app/components/magazine-page.vue' /* webpackChunkName: "components/magazine-page" */).then(c => wrapFunctional(c.default || c))
export const MagazinePost = () => import('../../app/components/magazine-post.vue' /* webpackChunkName: "components/magazine-post" */).then(c => wrapFunctional(c.default || c))
export const MagazineSearch = () => import('../../app/components/magazine-search.vue' /* webpackChunkName: "components/magazine-search" */).then(c => wrapFunctional(c.default || c))
export const SecondaryNavigation = () => import('../../app/components/secondary-navigation.vue' /* webpackChunkName: "components/secondary-navigation" */).then(c => wrapFunctional(c.default || c))
export const TidalPromotion = () => import('../../app/components/tidal-promotion.vue' /* webpackChunkName: "components/tidal-promotion" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

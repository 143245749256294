import { v4 as uuidv4 } from 'uuid';
import stringify from 'fast-safe-stringify';
import { captureUtms, getTidalTrackingCookieOptions, TIDAL_TRACKING_COOKIE_NAME } from '@tidal/tracking';
export default (function (context) {
    const { $cookieConsent, $config } = context;
    const trackingConfig = $config.tracking;
    if (!trackingConfig) {
        throw new Error('[@tidal/tracking] Missing "tracking" configuration. Can not initialize server-side plugin.');
    }
    if (trackingConfig.enable) {
        $cookieConsent.onConsentChanged(() => {
            onConsentChanged(context, trackingConfig);
        });
    }
});
function onConsentChanged(context, moduleOptions) {
    const { $cookieConsent, $cookies, $tracking, route } = context;
    if (!$cookieConsent.isCookieAllowed('targeting')) {
        return;
    }
    const { query } = route;
    const defaultCookieOptions = {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
        sameSite: 'lax',
        ...moduleOptions.cookieOptions,
    };
    // TIDAL Tracking
    if (moduleOptions.integrations?.tidalTracking) {
        const trackingData = $tracking.getTidalTrackingData();
        let setCookie = false;
        if (!trackingData.uuid) {
            trackingData.uuid = uuidv4();
            setCookie = true;
        }
        if (captureUtms(trackingData, query)) {
            setCookie = true;
        }
        if (setCookie) {
            const cookieOptions = getTidalTrackingCookieOptions(defaultCookieOptions);
            $cookies.set(TIDAL_TRACKING_COOKIE_NAME, stringify(trackingData), cookieOptions);
        }
    }
}


export default {
    props: {
        /** @type {import('vue').PropOptions<import('@nuxt/types').NuxtError>} */
        error: {
            type: Object,
            required: true,
        },
    },
};

import { SET_CURRENT_TIMESTAMP, SET_COUNTRY_GEO, SET_ENV_VARS } from '~/store/mutationTypes';
import { ACTION_DETECT_COUNTRY_GEO } from '~/store/actionTypes';

const COUNTRY_COOKIE = 'app_country';
const DEFAULT_COUNTRY = 'us';
const COUNTRIES_NO_VIDEOS = ['ae'];

/**
 * @typedef {{
 *   country: string
 *   disableVideos: boolean
 *   env: {[key: string]: string}
 *   timestamp: number
 * }} State
 *
 * @typedef {import('vuex').Store<State>} AppStore
 */

/** @return {AppStore['state']} */
export const state = () => ({
    country: DEFAULT_COUNTRY,
    disableVideos: false,
    env: {},
    timestamp: 0,
});

/** @type {import('vuex').MutationTree<State>} */
export const mutations = {
    [SET_CURRENT_TIMESTAMP](stateStore, timestamp) {
        stateStore.timestamp = timestamp;
    },
    [SET_ENV_VARS](stateStore, env) {
        stateStore.env = Object.assign({}, env);
    },
    [SET_COUNTRY_GEO](stateStore, country) {
        country = country.toLowerCase();
        stateStore.country = country;
        stateStore.disableVideos = COUNTRIES_NO_VIDEOS.includes(country);
    },
};

/** @type {import('vuex').ActionTree<State, State>} */
export const actions = {
    /** nuxtServerInit is called by Nuxt.js before server-rendering every page */
    async nuxtServerInit({ commit, dispatch }, { app, env, req }) {
        commit(SET_ENV_VARS, env);

        await dispatch(ACTION_DETECT_COUNTRY_GEO, {
            req,
            $cookies: app.$cookies,
        });
    },
    async [ACTION_DETECT_COUNTRY_GEO]({ commit }, { req, $cookies }) {
        /**
         * Determines if valid country.
         *
         * @param {string} code The country code
         * @return {boolean} True if valid country, False otherwise.
         */
        const isValidCountry = code => typeof code === 'string' && code.length === 2;

        let detectedCountry = DEFAULT_COUNTRY;
        const countryCookie = $cookies.get(COUNTRY_COOKIE);

        if (isValidCountry(countryCookie)) {
            detectedCountry = countryCookie;
        } else {
            const country = req.countryCode;

            if (country && isValidCountry(country)) {
                detectedCountry = country;
                $cookies.set(COUNTRY_COOKIE, detectedCountry);
            }
        }

        // this.$gtm.push({
        //     geo: detectedCountry.toUpperCase(),
        // });

        commit(SET_COUNTRY_GEO, detectedCountry);
    },
};

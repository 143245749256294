import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _51e1ce9e = () => interopDefault(import('../app/pages/article.vue' /* webpackChunkName: "pages/article" */))
const _0336fbc0 = () => interopDefault(import('../app/pages/author.vue' /* webpackChunkName: "pages/author" */))
const _4ff42fce = () => interopDefault(import('../app/pages/explore.vue' /* webpackChunkName: "pages/explore" */))
const _2694bc42 = () => interopDefault(import('../app/pages/page-preview.vue' /* webpackChunkName: "pages/page-preview" */))
const _2ea1bc16 = () => interopDefault(import('../app/pages/post.vue' /* webpackChunkName: "pages/post" */))
const _2d886fbd = () => interopDefault(import('../app/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _7118266b = () => interopDefault(import('../app/pages/v1/health.vue' /* webpackChunkName: "pages/v1/health" */))
const _ef5e5966 = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/magazine/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/article/:slug?/:id?",
    component: _51e1ce9e,
    name: "article"
  }, {
    path: "/author/:slug?/:id?/:page?",
    component: _0336fbc0,
    name: "author"
  }, {
    path: "/explore",
    component: _4ff42fce,
    name: "explore"
  }, {
    path: "/page/preview/:id?",
    component: _2694bc42,
    name: "page-preview"
  }, {
    path: "/:postType(tag|category)/:slug/:page(\\d+)?",
    component: _2ea1bc16,
    name: "post"
  }, {
    path: "/search",
    component: _2d886fbd,
    name: "search"
  }, {
    path: "/v1/health",
    component: _7118266b,
    name: "v1-health"
  }, {
    path: "/:slug?",
    component: _ef5e5966,
    name: "index"
  }, {
    path: "/:postType(tag|category)/:parent/:slug/:page(\\d+)?",
    component: _2ea1bc16,
    name: "post-with-parent"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

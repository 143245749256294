import { v4 as uuidv4 } from 'uuid';
import CookieUniversal from 'cookie-universal';

function urlJoin() {
    return [].slice.call(arguments).join('/').replace(/\/+/g, '/').replace(':/', '://');
}

export default class TidalAuth {
    /**
     * @param {import('@nuxt/types').Context} context The Nuxt context
     * @param {import('@tidal/auth').ModuleOptionsMerged} options The module options
     */
    constructor(context, options) {
        this.context = context;
        this.options = options;

        /** @type {import('@tidal/auth').AuthStoreState | null}*/
        this.state = null;
        this.storeNamespace = this.options.vuex.namespace;
        this.cookieStorage = CookieUniversal(this.context.req, this.context.res);
    }

    get user() {
        return this.state ? this.state.user : null;
    }

    async getUserHasActiveSubscription() {
        const { $axios } = this.context.app;
        if (this.isLoggedIn()) {
            const subscription = await $axios.$get('/auth/session/user/subscription');
            return subscription.status !== 'NO_SUBSCRIPTION' && subscription.subscription && subscription.subscription.type !== 'FREE';
        }
        return false;
    }

    getAndClearTransientData() {
        if (this.state?.transientData) {
            const data = this.state.transientData;
            this.context.store.commit(`${this.storeNamespace}/SET_TRANSIENT_DATA`, null);
            return data;
        }

        return null;
    }

    isLoggedIn() {
        return this.state && this.state.user !== null;
    }

    async init() {
        /** @type {import('vuex').Module<import('@tidal/auth').AuthStoreState, any>} */
        const storeModule = {
            namespaced: true,
            state: () => ({
                user: null,
                transientData: null,
            }),
            mutations: {
                SET_USER(state, payload) {
                    state.user = payload;
                },
                SET_TRANSIENT_DATA(state, payload) {
                    state.transientData = payload;
                },
            },
        };

        this.context.store.registerModule(this.storeNamespace, storeModule, {
            preserveState: Boolean(this.context.store.state[this.storeNamespace]),
        });

        this.state = this.context.store.state[this.storeNamespace];

        if (process.server) {
            const isOnExcludedRoute = this.options.excludeRoutes && this.context.route.name && this.options.excludeRoutes.includes(this.context.route.name);
            if (!isOnExcludedRoute) {
                await this._initUser();
            }
        }
    }

    async _initUser() {
        console.assert(process.server);

        /** @type {import('@tidal/web-session').GlobalUser & { token?: string } | null} */
        let user = null;

        try {
            user = await this.context.app.$axios.$get('/auth/session/user');
        } catch (error) {
            console.error('Failed fetching user\'s session.', error);
        }

        if (!user) {
            return null;
        }

        this._setUser({ ...user });
        this._setTransientData();
    }

    /** @param {import('@tidal/auth').AuthenticatedUser | null} value */
    _setUser(value) {
        this.context.store.commit(`${this.storeNamespace}/SET_USER`, value);
    }

    _setTransientData() {
        console.assert(process.server);

        const data = this.cookieStorage.get(this.options.storageKeys.transientData);
        if (data) {
            this.cookieStorage.remove(this.options.storageKeys.transientData, { ...this.options.defaultCookieOptions });
            this.context.store.commit(`${this.storeNamespace}/SET_TRANSIENT_DATA`, data);
        }
    }

    /**
     * @param {import('@tidal/auth').LoginParams} params
     */
    login({ extraAuthParams = {}, transientData, redirectHandler }) {
        const authorizationUrl = this.options.endpoints.authorization;

        const extraQueryParams =
          typeof (this.options.extraAuthParams) === 'function'
              ? this.options.extraAuthParams(this.context)
              : this.options.extraAuthParams;

        const queryParams = {
            ...extraQueryParams,
            ...extraAuthParams,
            ...this.options.oauthParams,
            // Note: The primary reason for using the state parameter is to mitigate CSRF attacks.
            // @see: https://auth0.com/docs/protocols/oauth2/oauth-state
            state: uuidv4(),
        };

        const redirectUrl = new URL(authorizationUrl);

        for (const [key, value] of Object.entries(queryParams)) {
            redirectUrl.searchParams.append(key, value);
        }

        // Strip autologin=true to avoid infinite loop.
        const originalPageUrl = this._getCurrentUrl().replace('autologin=true', 'autologin=false');

        this.cookieStorage.set(this.options.storageKeys.authState, {
            state: queryParams.state,
            originalPageUrl,
        }, { ...this.options.defaultCookieOptions });

        if (transientData) {
            this.cookieStorage.set(this.options.storageKeys.transientData, transientData, { ...this.options.defaultCookieOptions });
        }

        redirectHandler(redirectUrl.toString());
    }

    async fetchAccessToken() {
        if (!this.state || this.state.user === null) {
            return null;
        }

        const { $axios } = this.context.app;
        return await $axios.$post('/auth/session/user/access-token');
    }

    /**
     * Saves a URL of the passed page or current page to be used for redirecting, after user is logged-in.
     *
     * @param {string} [url]
     */
    saveReturnUrl(url) {
        this.cookieStorage.set(this.options.storageKeys.returnUrl, url || this._getCurrentUrl(), {
            maxAge: 60 * 5,  // 5 minutes
            ...this.options.defaultCookieOptions,
        });
    }

    /**
     * Returns full path of currently loaded route.
     *
     * @return {string} The current url.
     */
    _getCurrentUrl() {
        return urlJoin(this.options.redirectBase, this.context.route.fullPath);
    }

    /**
     * Logs the user out.
     *
     * @param {import('@tidal/auth').LogoutOptions} options
     */
    logout(options) {
        const { stayOnPage, cleanLocalSession, redirectHandler } = options;
        if (this.isLoggedIn()) {
            const logoutPath = urlJoin(this.options.redirectBase, 'auth', this.options.endpoints.logout);
            const logoutUrl = new URL(logoutPath);

            const returnUrl = stayOnPage
                ? this._getCurrentUrl()
                : urlJoin(this.options.redirectBase, this.options.endpoints.login);

            logoutUrl.searchParams.append('redirectUrl', returnUrl);

            if (cleanLocalSession) {
                logoutUrl.searchParams.append('clean', 'true');
            }

            this._setUser(null);

            redirectHandler(logoutUrl.toString());
        }
    }
}

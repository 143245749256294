import { ENV_DIRECTORY, IS_DEVELOPMENT, IS_PRODUCTION } from './bootstrap-constants';

if (!process.env.API_URL_BROWSER) {
    throw new Error('The constants.js file can only be imported after dotenv config was evaluated.');
}

const rootPath = process.env.ROOT_PATH;
const magazineUrl = process.env.TIDAL_COM_URL + (rootPath.endsWith('/') ? rootPath.slice(0, -1) : rootPath);

export default {
    app: {
        name: 'TIDAL Magazine',
        shortName: 'Magazine',
        tagline: 'The Stories Behind the Music',
        description: 'Read our integrated music articles, features and interviews',
    },
    rootPath,
    tidalComUrl: process.env.TIDAL_COM_URL,
    tidalDomain: process.env.TIDAL_DOMAIN,
    magazineUrl,
    magazineApiHost: process.env.MAGAZINE_API_HOST,
    magazineToken: 'a804ab1a921272ec',
    previewToken: 'n78sbrps1hqau7l27m3x9fbfrmt4norna80njitlsj3ifznlljluxujqdr42jqvo',
    API_URL_BROWSER: process.env.API_URL_BROWSER,
    APP_DIRECTORY: 'app/',
    /** @type {'prod' | 'stage' | 'dev'} */
    // @ts-ignore
    ENV: IS_PRODUCTION ? 'prod' : IS_DEVELOPMENT ? 'dev' : 'stage',
    ENV_DIRECTORY,
    DEFAULT_LOCALE: 'en',
    IS_DEVELOPMENT,
    IS_PRODUCTION,
};

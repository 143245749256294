
export default {
    methods: {
        /**
         * @param {string} translationKey
         * @return {string} The translation.
         */
        getTranslation(translationKey) {
            return String(this.$t(`webCommon.footer.${translationKey}`));
        },
        /**
         * @param {string} locale
         */
        onLanguageChanged(locale) {
            if (locale === this.$i18n.localeProperties.lang) {
                return;
            }

            this.$i18n.setLocale(locale);
        },
    },
};

import Vue from 'vue';
import { compile as compilePath }  from '~path-to-regexp';
import ApiHandler from '~/config/nuxt-neo-client';

const SuccessHandler = null;

    const ErrorHandler = null;

/**
 * Runs possible success handler
 *
 * @param result
 * @returns {Function}
 */
function runSuccessHandler(result) {
    return SuccessHandler && SuccessHandler(result) || Promise.resolve(result);
}

/**
 * Runs possible error handler
 *
 * @param error
 * @returns {Function}
 */
function runErrorHandler(error) {
    return ErrorHandler && ErrorHandler(error) || Promise.reject(error);
}

/**
 * Generates the API tree for the client side.
 *
 * @param controllerMapping
 * @param {import('@nuxt/types').Context} ctx
 * @return Object
 */
function generateAPI(controllerMapping, ctx) {
    const api = {};
    for (const key of Object.keys(controllerMapping)) {
        const context = controllerMapping[key];
        if (context && context.path && context.verb) {
            const pathCompiler = compilePath(context.path, { encode: encodeURIComponent });
            api[key] = function ({params, ...values} = {}) {
                let compiledPath;
                try {
                    compiledPath = pathCompiler(params);
                } catch (error) {
                    throw new Error(`Error calling the nuxt-neo API (name: ${key}, path: ${context.path}): ${error.message}`);
                }
                return ApiHandler(
                    compiledPath,
                    context.verb,
                    values || {},
                    {"directory":"/home/node/app/app/api","prefix":"/api","bodyParsers":"json","httpErrors":true,"debug":false,"noContentStatusOnEmpty":true,"clientSideApiHandler":"~/config/nuxt-neo-client","successHandler":null,"errorHandler":null,"aliasKey":{},"srcDir":"/home/node/app/app"},
                    ctx
                )
                    .then(runSuccessHandler)
                    .catch(err => {
                        err.ctx = ctx;
                        const result = runErrorHandler(err);
                        delete err.ctx;
                        return result;
                    });
            }
        } else if (typeof controllerMapping[key] === 'object') {
            api[key] = generateAPI(controllerMapping[key], ctx);
        }
    }

    return api;
}

export default async (ctx, inject) => {
    const $api = process.server ? await ctx.req._controllersTree(ctx) : generateAPI({"magazine":{"getAuthor":{"path":"/magazine/author/:id","verb":"GET"},"getArticle":{"path":"/magazine/article/:slug/:id","verb":"GET"},"getArticleBySlug":{"path":"/magazine/article/:slug","verb":"GET"},"getArticles":{"path":"/magazine/articles/:limit?/:offset?/:filter?","verb":"GET"},"getArticlesBySearch":{"path":"/magazine/articles-by-search","verb":"GET"},"getRelatedArticles":{"path":"/magazine/article/related/:id/:tags?","verb":"GET"},"getCategories":{"path":"/magazine/categories","verb":"GET"},"getCategoryBySlug":{"path":"/magazine/category/:slug","verb":"GET"},"getMenu":{"path":"/magazine/menu/:menu?","verb":"GET"},"getPage":{"path":"/magazine/page-slug/:id","verb":"GET"},"getPageBySlug":{"path":"/magazine/page/:slug","verb":"GET"},"getTagBySlug":{"path":"/magazine/tag/:slug","verb":"GET"}}}, ctx);
    inject('api', $api);
};

import { render, staticRenderFns } from "./default-layout.vue?vue&type=template&id=ede9a826&"
import script from "./default-layout.vue?vue&type=script&lang=js&"
export * from "./default-layout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderComponent: require('/home/node/app/app/components/header-component.vue').default,SecondaryNavigation: require('/home/node/app/app/components/secondary-navigation.vue').default,TidalPromotion: require('/home/node/app/app/components/tidal-promotion.vue').default,FooterComponent: require('/home/node/app/app/components/footer-component.vue').default})

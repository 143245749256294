import constants from './constants';

/**
 * @param {string} path The relative path of the route
 * @param {string} verb The method type
 * @param {{ query: any, body: any, params: any }} payload The given payload to send
 * @param {import('nuxt-neo').ResolvedModuleOptions} options The API options (prefix is the api prefix, e.g: '/api')
 * @param {import('@nuxt/types').Context} context
 */
export default async function(path, verb, { query, body }, { prefix }, { $axios }) {
    return await $axios.$request({
        baseURL: `${constants.API_URL_BROWSER}${prefix || ''}`,
        timeout: 5000,
        url: path,
        method: /** @type {import('axios').Method} */(verb.toLowerCase()),
        data: body,
        params: query,
    });
}

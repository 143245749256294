import constants from '~/config/constants';

const DEFAULT_IMAGE = 'https://tidal-cms.s3.amazonaws.com/assets/b7ff0a90-575e-11ec-bd41-e99425a52037/OG-generic.jpg';
const DEFAULT_IMAGE_WIDTH = 1200;
const DEFAULT_IMAGE_HEIGHT = 630;

/**
 * Creates meta tags for page.
 *
 * @type {TIDAL.CreatePageTagsFunction}
 */
function createMeta({
    title = constants.app.tagline,
    description = constants.app.description,
    imageUrl = DEFAULT_IMAGE,
    imageWidth = DEFAULT_IMAGE_WIDTH,
    imageHeight = DEFAULT_IMAGE_HEIGHT,
    structuredData = undefined,
} = {}) {
    // Makes typescript happy (translation can in theory be an object but it won't happen for us).
    title = String(title);
    const meta = [
        { name: 'description', content: description, hid: 'desc' },
        { name: 'twitter:card', content: 'summary_large_image', hid: 't_card' },
        { name: 'twitter:site', content: '@tidal', hid: 't_site' },
        { name: 'twitter:title', content: title || constants.app.tagline, hid: 't_t' },
        { name: 'twitter:decription', content: description, hid: 't_desc' },
        { property: 'og:title', content: title || constants.app.tagline, hid: 'og_t' },
        { property: 'og:description', content: description, hid: 'og_desc' },
        { property: 'og:type', content: 'website', hid: 'og_type' },
        { property: 'og:site_name', content: constants.app.tagline, hid: 'og_site' },
    ];

    meta.push({ property: 'og:image', content: imageUrl, hid: 'og_img' });
    meta.push({ property: 'og:image:width', content: String(imageWidth), hid: 'og_img_w' });
    meta.push({ property: 'og:image:height', content: String(imageHeight), hid: 'og_img_h' });

    const script = [];

    if (structuredData) {
        script.push({
            type: 'application/ld+json',
            json: structuredData,
            hid: 'sdata',
        });
    }

    return { meta, title, script };
}

export default createMeta;

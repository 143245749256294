import { createAxiosInstance } from './axios-extend.js'

const options = {"baseURL":"http:\u002F\u002Flocalhost:9000\u002Fmagazine\u002F","browserBaseURL":"https:\u002F\u002Ftidal.com\u002Fmagazine","credentials":false,"debug":false,"progress":true,"proxyHeaders":true,"proxyHeadersIgnore":["accept","cf-connecting-ip","cf-ray","content-length","content-md5","content-type","host","x-forwarded-host","x-forwarded-port","x-forwarded-proto","if-none-match","upgrade-insecure-requests"],"proxy":true,"retry":false,"https":false,"headers":{"common":{"Accept":"application\u002Fjson, text\u002Fplain, *\u002F*"},"delete":{},"get":{},"head":{},"post":{},"put":{},"patch":{}},"prefix":"\u002Fmagazine\u002F","timeout":5000,"globalName":"nuxt"}

export default async (ctx, inject) => {
  // runtimeConfig
  const runtimeConfig = ctx.$config && ctx.$config.axios || {}
  // baseURL
  const baseURL = process.client
    ? (runtimeConfig.browserBaseURL || runtimeConfig.browserBaseUrl || runtimeConfig.baseURL || runtimeConfig.baseUrl || options.browserBaseURL || '')
      : (runtimeConfig.baseURL || runtimeConfig.baseUrl || process.env._AXIOS_BASE_URL_ || options.baseURL || '')
  const timeout = runtimeConfig.timeout || 5000

  // Create fresh objects for all default header scopes
  // Axios creates only one which is shared across SSR requests!
  // https://github.com/mzabriskie/axios/blob/master/lib/defaults.js
  const headers = {
    "common": {
        "Accept": "application/json, text/plain, */*"
    },
    "delete": {},
    "get": {},
    "head": {},
    "post": {},
    "put": {},
    "patch": {}
}

  const axiosOptions = {
    baseURL,
    headers,
    timeout
  }

  const axios = createAxiosInstance(axiosOptions, options, process.server, ctx.req)

  // Inject axios to the context as $axios
  inject('axios', axios)
}

import Vue from 'vue';

/** @type {import('vue').PluginObject<any>} */
const plugin = {
    install: VueInstance => {
        VueInstance.mixin({
            computed: {
                /**
                 * @this Vue
                 * @return {string}
                 */
                siteBaseUrl() {
                    const baseUrl = this.localePath('index');
                    return baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
                },
                /**
                 * @this Vue
                 * @return {string}
                 */
                routeBrowserUrl() {
                    return `${process.env.API_URL_BROWSER}${this.$route.fullPath}`;
                },
            },
            methods: {
                /**
                 * @param {string} url
                 * @return {string}
                 */
                assetUrl(url) {
                    const separator = url.startsWith('/') ? '' : '/';
                    return require(`~/assets${separator}${url}`);
                },
            },
        });
    },
};

export default () => Vue.use(plugin);

import { ClientTrackingApi, dummyTrackingApi } from '@tidal/tracking';
export default (async function ({ $axios, $cookieConsent, $cookies, $config, app }, inject) {
    if (!$config.tracking) {
        throw new Error('[@tidal/tracking] Missing "tracking" configuration. Can not initialize server-side plugin.');
    }
    const { enable } = $config.tracking;
    if (enable) {
        const trackingApi = new ClientTrackingApi({
            $cookieConsent,
            $cookies,
            axios: $axios,
            moduleOptions: $config.tracking,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            router: app.router,
        });
        app.router?.afterEach(to => {
            setTimeout(() => {
                const title = typeof document !== 'undefined' && document.title || '';
                trackingApi.triggerPageViewEvent(to, title);
            }, 250);
        });
        inject('tracking', trackingApi);
    }
    else {
        inject('tracking', dummyTrackingApi);
    }
});


export default {
    props: {
        margins: {
            type: Boolean,
            default: true,
        },
        animations: {
            type: Boolean,
            default: false,
        },
        lazyload: {
            type: Boolean,
            default: false,
        },
    },
};


export default {
    props: {
        /** @type {import('vue').PropOptions<TIDAL.MenuItem[] | null>} */
        magazineNav: {
            type: Array,
            default: null,
        },
        active: {
            type: String,
            default: null,
        },
        darkBg: {
            type: Boolean,
            default: false,
        },
        /** @type {import('vue').PropOptions<{ backgroundColor: string, color: string | undefined }>} */
        customArticleStyle: {
            type: Object,
            default: null,
        },
        isTakeover: {
            type: Boolean,
            default: false,
        },
        mainClass: {
            type: String,
            default: null,
        },
        minimalFooter: {
            type: Boolean,
            default: false,
        },
        hidePromotion: {
            type: Boolean,
            default: false,
        },
    },
    head() {
        return {
            bodyAttrs: {
                class: this.darkBg ? 'theme-dark' : 'theme-native',
            },
        };
    },
    methods: {
        /**
         * @param {string} key The key
         * @return {string} The cookie translation.
         */
        getCookieTranslation(key) {
            let translationKey = '';

            switch (key) {
                case 'title':
                    translationKey = 'webCommon.cookie.title';
                    break;
                case 'text':
                    translationKey = 'webCommon.cookie.text';
                    break;
                case 'buttonText':
                    translationKey = 'webCommon.button.iAccept';
                    break;

                case 'policyDesktop':
                    translationKey = this.$store.state.country === 'us' ? 'webCommon.cookie.policy.US' : 'webCommon.cookie.policyDesktop';
                    break;
                case 'policyMobile':
                    translationKey = this.$store.state.country === 'us' ? 'webCommon.cookie.policy.US' : 'webCommon.cookie.policyMobile';
                    break;
            }

            return translationKey ? String(this.$t(translationKey)) : '';
        },
    },
};

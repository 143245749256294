import { render, staticRenderFns } from "./footer-component.vue?vue&type=template&id=fb6eab30&"
import script from "./footer-component.vue?vue&type=script&lang=js&"
export * from "./footer-component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TidalLanguageSwitcher: require('/home/node/app/node_modules/@tidal/language-switcher/src/components/tidal-language-switcher.vue').default,TidalFooter: require('/home/node/app/node_modules/@tidal/styles-footer/src/components/tidal-footer.vue').default})
